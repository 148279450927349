<template>
  <!--
    Enables upload of files to the amazon cloud
  -->
  <div class="staticResourceUploader">
    <Portlet
      :title="$t('staticResourceUploaderComponent.staticResourceUploader')"
      icon="upload"
    >
      <LoadingPlaceholder v-if="loading == true" />

      <div
        v-else
        class="row"
      >
        <div class="col-12 col-xl-3 mb-3">
          <Portlet
            :title="$t('staticResourceUploaderComponent.upload')"
            icon="upload"
          >
            <div class="input-group mb-3">
              <div class="input-prepend mr-3">
                <label style="width:50px;">{{ $t('name') }}</label>
              </div>
              <select
                v-model="imageName"
                class="form-control cursor-pointer rounded"
                @change="uploadPercentage = 0"
              >
                <option value="">
                  {{ $t('pleaseSelectOne') }}
                </option>
                <option
                  v-for="(name, nameIndex) in imageNames"
                  :key="`imageUploadImageNameSelectOption-${ nameIndex }`"
                  :value="name"
                >
                  {{ name }}
                </option>
              </select>
            </div>

            <div class="input-group mb-3">
              <div class="input-prepend mr-3">
                <label style="width:50px;">{{ $t('staticResourceUploaderComponent.file') }}</label>
              </div>
              <input
                ref="fileInput"
                type="file"
                class="form-control cursor-pointer rounded"
                @change="getFileFromInput($event)"
              >
            </div>
            
            <div
              v-if="uploadPercentage > 0"
              class="input-group mb-3"
            >
              <div class="input-prepend mr-3">
                <label style="width:50px;">{{ $t('staticResourceUploaderComponent.progress') }}</label>
              </div>
              <div class="progress">
                <div
                  :aria-valuenow="uploadPercentage"
                  :style="`width:${ uploadPercentage }%;`"
                  class="progress-bar"
                  role="progressbar"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {{ uploadPercentage }} %
                </div>
              </div>
            </div>
            
            <template v-if="uploadPercentage == 0">
              <button
                v-if="imageName.length > 0 && image != null"
                class="btn btn-primary btn-block"
                @click="saveFile()"
              >
                <font-awesome-icon
                  class="mr-1"
                  icon="upload"
                />
                <span>
                  {{ $t('upload') }}
                </span>
              </button>
              <button
                v-else
                class="btn btn-primary btn-block cursor-not-allowed"
                disabled
              >
                <font-awesome-icon
                  class="mr-1"
                  icon="upload"
                />
                <span>
                  {{ $t('upload') }}
                </span>
              </button>
            </template>
          </Portlet>
        </div>
        <div class="col-12 col-xl-9">
          <Portlet
            :title="$t('staticResourceUploaderComponent.files')"
            icon="image"
          >
            <table
              v-if="filesInList != null"
              class="defaultTable"
            >
              <colgroup>
                <col width="150">
                <col
                  class="hide-down-lg"
                  width="75"
                >
                <col
                  class="hide-down-xl"
                  width="150"
                >
                <col>
                <col
                  class="hide-down-sm"
                  width="100"
                >
                <col
                  class="hide-down-sm"
                  width="150"
                >
              </colgroup>
              <thead>
                <tr>
                  <th>{{ $t('name') }}</th>
                  <th class="hide-down-lg">
                    {{ $t('size') }}
                  </th>
                  <th class="hide-down-xl">
                    {{ $t('date') }}
                  </th>
                  <th>{{ $t('path') }}</th>
                  <th class="hide-down-sm">
                    {{ $t('preview') }}
                  </th>
                  <th class="hide-down-sm">
                    {{ $t('options') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(file, fileIndex) in filesInList"
                  :key="`imageUploadImage-${ fileIndex }`"
                >
                  <td>{{ file.name }}</td>
                  <td
                    v-tooltip="`${ file.size } bytes`"
                    class="hide-down-lg"
                  >
                    {{ numberConversionAdapter_bytes(file.size).bytes }} {{ numberConversionAdapter_bytes(file.size).unit }}
                  </td>
                  <td
                    v-tooltip="dateTime_fromNow(file.lastChangeDate)"
                    class="hide-down-xl"
                  >
                    {{ dateTime_dateTime(file.lastChangeDate) }}
                  </td>
                  <td>
                    <a
                      :href="`${cloudPath}${ file.path }`"
                      target="_blank"
                      class="cursor-pointer d-inline-block"
                    >{{ file.path }}</a>
                  </td>
                  <td class="align-middle text-center hide-down-sm">
                    <img
                      v-if="file.path.includes('.png') || file.path.includes('.jpg') || file.path.includes('.jpeg')"
                      :src="`${cloudPath}${ file.path }?${ imageUploadedCount }`"
                      :alt="file.name"
                      class="previewImage"
                    >
                    <font-awesome-icon
                      v-else-if="file.path.includes('.zip')"
                      class="placeholderIcon"
                      icon="folder"
                    />
                  </td>
                  <td class="hide-down-sm">
                    <button
                      class="btn btn-sm btn-block btn-danger"
                      @click="deleteResource(file.name)"
                    >
                      <font-awesome-icon
                        class="mr-2"
                        icon="trash"
                      />
                      <span>{{ $t('delete') }}</span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <p v-else>
              {{ $t('noDataAvailable') }}
            </p>
          </Portlet>
        </div>
      </div>
    </Portlet>
  </div>
</template>

<script>
import numberConversionAdapter from '@/adapter/numberConversionAdapter.js';
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
export default {
  name: "StaticResourceUploader",
  mixins: [
    dateTimeMixin,
    numberConversionAdapter
  ],
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      uploadingRightNow: false,
      uploadPercentage: 0,
      imageUploadedCount: 0,
      loading: true,
      files: null,
      image: null,
      imageName: '',
      imageNames: null,
      installation: null
    }
  },
  computed: {
    filesInList () {
      if (this.files == null) {
        return null;
      }
      if (this.imageNames == null) {
        return null;
      }
      let result = [];
      this.files.forEach(file => {
        if (this.imageNames.indexOf(file.name) > -1) {
          result.push(file);
        }
      });
      return result.length > 0 ? result : null;
    },
    cloudPath () {
      if(this.installation != null) {
        if(this.installation.country == "China") {
          return "https://skiline-china.oss-cn-zhangjiakou.aliyuncs.com/"
        } 
        else {
          return "https://skiline.s3-eu-west-1.amazonaws.com/"
        } 
      }
      return "https://skiline.s3-eu-west-1.amazonaws.com/"
    }
  },
  created () {
    this.getInstallation();
    this.getData();
  },
  methods: {
    getFileFromInput () {
      this.image = this.$refs.fileInput.files[0];
      this.uploadPercentage = 0;
    },
    saveFile () {
      this.uploadingRightNow = true;
      this.uploadPercentage = 0;
      let bodyFormData = new FormData();
      bodyFormData.append('formFile', this.image);

      this.axios.post(`/StaticResource/Upload/${ this.installationId }?fileName=${ this.imageName }`,
        bodyFormData,
        { headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: function ( progressEvent ) {
            this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
          }.bind(this)
        }
      )
      .then((response) => {
        if (response && response.status && response.status == 200) {
          this.$snotify.success(this.$t('staticResourceUploaderComponent.fileSavedMessage'), this.$t('staticResourceUploaderComponent.fileSavedTitle'));
        }
      })
      .finally(() => {
        this.uploadingRightNow = false;
        this.getData();
        setTimeout(() => {
          this.uploadPercentage = 0;
        }, 2500);
      });
    },
    getData () {
      this.axios.all([
        this.axios.get(`/StaticResource/Get/${ this.installationId }`),
        this.axios.post(`/StaticResource/GetAllowedFileNames/${ this.installationId }`)
      ])
      .then(this.axios.spread((filesResponse, namesResponse) => {
        if (filesResponse != null && filesResponse.data != null) {
          this.files = filesResponse.data;
        }
        if (namesResponse != null && namesResponse.data != null) {
          this.imageNames = namesResponse.data;
        }
      }))
      .finally(() => {
        this.imageUploadedCount++;
        this.loading = false;
      });
    },
    getInstallation () {
      this.axios.get('/Installation/Get?id=' + this.installationId)
        .then((response) => {
          this.installation = response.data;
        });
    },
    deleteResource (imageName) {
      this.axios.delete(`/StaticResource/Delete/${ this.installationId }/${ imageName }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.status == null) {
            return;
          }
          if (response.status == 200) {
          this.$snotify.success(this.$t('staticResourceUploaderComponent.fileDeletedMessage'), this.$t('staticResourceUploaderComponent.fileDeletedTitle'));
          }
        });
    }
  }
}
</script>

<style>
.staticResourceUploader input[type="file"] {
  height: 40px;
  padding-top: 8px;
  padding-left: 8px;
}
.staticResourceUploader .previewImage {
  width: 100%;
  height: auto;
  display: block;
}
.staticResourceUploader .placeholderIcon {
  font-size: 2rem;
}
.staticResourceUploader .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>
